/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import '~bootstrap/scss/bootstrap';
@import '~font-awesome/scss/font-awesome';
@import '~simple-line-icons/scss/simple-line-icons';
@import 'typography';
@import 'elevation';
@import 'components/dropdown';
@import 'components/card';
@import 'components/buttons';
@import 'components/forms';
@import 'components/icons';
@import 'components/inputs';
@import 'components/tabs';
@import 'components/gallery';
@import 'components/lists';
@import 'components/rating';
@import 'components/toggle';
@import 'components/table';
@import 'components/tooltip';
@import 'components/progress';
@import 'components/modal';
@import 'utils/utils';
@import "~nouislider/dist/nouislider.min.css";
@include all-utils();

html {
  height: 100%;
}
body {
  height: 100%;
}
//Angular router components
router-outlet ~ * {
  display: block;
  height: 100%;
  min-height: 100%;
  position: relative;
}


.form-group.required > label {
    /* color: #ff0000; */
}

.form-group.required > label:after {
    color: #ff0000;
    content: '*';
}


.required :root .requiredinfo {
    display: none;
}

/*.form-group.required input {
    border-color: #ff0000;
}

.form-group.required input:focus {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0), 0px 0px 7px 0px rgba(255, 0, 0, 0.53);
}*/