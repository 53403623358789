@import '../elevation';

.dropdown-menu {
  @include mat-elevation(10);
}

.dropdown-header {
  font-size: $font-size-base;
  color: $body-color;
}

.dropdown-divider {
  border-width: 2px;
}

.dropdown-item {
  transition: color 0.2s ease;
}

.dropdown-no-toggle {
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
  i {
    cursor: pointer;
  }
}

.dropdown-bounce {
  .dropdown-menu {
    display: block !important;
    visibility: hidden;
    transform: translateY(-200%);
    transition: transform 0.3s cubic-bezier(0.38, -0.26, 0.49, -0.09), visibility 0s linear 0.3s;
  }
  &.show .dropdown-menu {
    visibility: visible;
    transform: translateY(0);
    transition: transform 0.5s cubic-bezier(0.25, 0.1, 0.18, 1.27);
  }
}
.dropdown-grow {
  .dropdown-menu {
    display: block !important;
    visibility: hidden;
    transform: scale(0.3);
    opacity: 0;
    transform-origin: top left;
    transition: opacity 0.3s ease, transform 0s linear 0.3s, visibility 0s linear 0.3s;
    > * {
      opacity: 0;
      transition: opacity 0s linear 0.3s;
    }
  }
  &.show .dropdown-menu {
    visibility: visible;
    transform: scale(1);
    opacity: 1;
    transition: transform 0.3s ease, opacity 0.3s ease;
    > * {
      opacity: 1;
      transition: opacity 0.3s ease 0.3s;
    }
  }
  &[placement="top"] {
    .dropdown-menu {
      transform-origin: bottom;
    }
  }
  &[placement="top-left"] {
    .dropdown-menu {
      transform-origin: bottom left;
    }
  }

  &[placement="top-right"] {
    .dropdown-menu {
      transform-origin: bottom right;
    }
  }
  &[placement="bottom"] {
    .dropdown-menu {
      transform-origin: top;
    }
  }
  &[placement="bottom-left"] {
    .dropdown-menu {
      transform-origin: top left;
    }
  }
  &[placement="bottom-right"] {
    .dropdown-menu {
      transform-origin: top right;
    }
  }
  &[placement="left"] {
    .dropdown-menu {
      transform-origin: right;
    }
  }
  &[placement="left-top"] {
    .dropdown-menu {
      transform-origin: right top;
    }
  }
  &[placement="left-bottom"] {
    .dropdown-menu {
      transform-origin: right bottom;
    }
  }
  &[placement="right"] {
    .dropdown-menu {
      transform-origin: left;
    }
  }
  &[placement="right-top"] {
    .dropdown-menu {
      transform-origin: left top;
    }
  }
  &[placement="right bottom"] {
    .dropdown-menu {
      transform-origin: left bottom;
    }
  }
}
